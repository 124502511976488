<template>
  <div v-if="selectedProveedor">
    <b-overlay
      :show="spinner"
      spinner-variant="primary"
    >
      <b-card>
        <proveedoresForm
          proveedorSubmit="Crear Proveedor"
          :proveedor="selectedProveedor"
          @processProveedor="agregarProveedor"
        />
      </b-card>
    </b-overlay>
  </div>
</template>

<script>

import { BCard, BOverlay } from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const proveedoresForm = () => import('@/layouts/components/Proveedores/ProveedoresForm.vue')

export default {
  components: {
    BCard,
    BOverlay,
    proveedoresForm,
  },
  data() {
    return {
      spinner: false,
    }
  },
  computed: {
    ...mapState('proveedores', ['selectedProveedor']),
  },
  methods: {
    ...mapActions({ createProveedor: 'proveedores/addProveedores' }),
    agregarProveedor(proveedor) {
      this.spinner = true
      this.createProveedor(proveedor).then(() => {
        const errorProveedores = store.state.proveedores
        const errorMessage = errorProveedores.errorMessage.errors
        if (!errorProveedores.error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Proveedor creado con éxito 👍',
              icon: 'CheckIcon',
              text: `El proveedor "${proveedor.nombre}" fue creado con éxito!`,
              variant: 'success',
            },
          },
          {
            timeout: 4000,
          })
          this.$router.replace({
            name: 'proveedores',
          })
        } else if (errorMessage.rut) {
          this.$swal({
            title: 'Error!',
            text: `${errorMessage.rut[0]}!`,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        } else {
          this.$swal({
            title: 'Error!',
            text: 'Ingreso de datos fraudulento!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
        this.spinner = false
      })
    },
  },
}
</script>
